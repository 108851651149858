export const skillsData = [
    'Python',
    'Tensorflow',
    'Numpy',
    'Azure',
    'GCP',
    'AWS',
    'Java',
    'Pytorch',
    'Matlab',
    'Jupyter Notebook',
    'pandas',
    'Matplotlib',
    'Seaborn',
    'Tableau',
    'Power BI',
    'Scikit-learn',
    'Keras',
    'XGBoost',
    'spaCy',
    'MLflow',
    'Statsmodels',
    'NLTK',
    'Docker',
    'MySQL',
    'PostgreSQL',
    'MongoDB',
    'Graphql',
    'Git',
    'OpenCV',
    'Ubuntu',
    'HTML',
    'CSS',
];
